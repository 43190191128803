import React, { useState } from "react";
import Login from "../../components/Login"; // Pastikan Login component sudah benar diimport
import background from "../../assets/img/backgroundutama.png";
import backgroundMobile from "../../assets/img/backgroundmobile.png";
import overlay from "../../assets/img/overlay.png";
import logo from "../../assets/img/logo.png";
import style from "../Login/style.css";

function Index(props) {
  const appName = "Login";
  const [showLogin, setShowLogin] = useState(false); // State untuk mengatur visibilitas form login popup
  const deskripsi = "Selamat datang di";
  const deskripsi2 = "Belum punya akun? Silakan hubungi Admin Sekolah";
  const title2 = "E-learning SMP Muhammadiyah 2 Yogyakarta";
  const copyright = "2024 SMP Muhammadiyah 2 Yogyakarta. - Didukung oleh";

  // Fungsi untuk menutup popup saat area luar konten di klik
  const handleClosePopup = (e) => {
    if (e.target.className === "login-popup") {
      setShowLogin(false);
    }
  };

  return (
    <>
      <div className="col-12 hero-container">
        <div
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.3) 100%, rgba(255, 255, 255, 0) 100%), url(${background})`,
          }}
          className="hero-background col-12"
        ></div>
        <div
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.3) 100%, rgba(255, 255, 255, 0) 100%), url(${backgroundMobile})`,
          }}
          className="hero-background hero-bg-mobile col-12"
        ></div>
        <div
          className="col-12 hero"
          style={{
            backgroundPosition: "0 100%",
            backgroundRepeat: "no-repeat",
            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.3) 81.78%, rgba(255, 255, 255, 0) 95.16%), url(${props.overlay})`,
          }}
        >
          <div className="">
            <div className="text-light" data-page={appName}>
              <div
                className="menu"
                style={{ textAlign: "right", textColor: "white" }}
              ></div>

              {/* Logo Mobile */}
              <div className="title-mobile d-block d-md-none">
                {/* d-block pada mobile, d-md-none untuk menyembunyikan di desktop */}
                <div className="logo logo-mobile">
                  <img
                    src={logo}
                    alt={appName}
                    style={{ width: "25vw", height: "25vw", marginTop: "5vh" }}
                  />
                  <h3
                    style={{
                      fontSize: "4vw",
                      marginTop: "2vh",
                      fontFamily: "Poppins",
                    }}
                  >
                    SMP MUHAMMADIYAH 2 YOGYAKARTA
                  </h3>
                </div>
                <h1
                  style={{
                    marginTop: "2vh",
                    fontSize: "6vw",
                    textAlign: "center",
                  }}
                >
                  {deskripsi}
                </h1>
                <h1
                  style={{
                    marginTop: "2vh",
                    fontSize: "10vw",
                    textAlign: "center",
                  }}
                >
                  {title2}
                </h1>
                <br />
                <div
                  className="login"
                  style={{
                    display: "inline-table",
                    width: "auto",
                    padding: "1vh 10vw",
                    marginTop: "2vh",
                    marginBottom: "2vh",
                    cursor: "pointer",
                    background: "#2F318B",
                    borderRadius: "20px",
                  }}
                  onClick={() => setShowLogin(true)} // Menampilkan login popup
                >
                  <span
                    style={{
                      fontSize: "4vw",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Login
                  </span>
                </div>
                <p style={{ fontSize: "2vw", fontFamily: "Poppins" }}>
                  {deskripsi2}
                </p>
                <div className="overlay">
                  <img
                    src={overlay}
                    alt={appName}
                    style={{
                      width: "47vw",
                      height: "41.5vh",
                      marginLeft: "5vw",
                    }}
                  />
                </div>
              </div>

              {/* Logo Desktop */}
              <div
                className="container d-none d-md-flex"
                // d-none pada mobile, d-md-flex pada desktop
                style={{
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "right",
                  paddingRight: "9vw",
                }}
              >
                <div
                  className="title-logo-desktop"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img src={logo} alt={appName} className="logo" />
                  <h3
                    style={{
                      fontSize: "2vw",
                      marginTop: "2vh",
                      fontFamily: "Poppins",
                    }}
                  >
                    SMP MUHAMMADIYAH 2 YOGYAKARTA
                  </h3>
                </div>

                <div
                  className="deskripsi"
                  style={{ textAlign: "right", maxWidth: "80vw" }}
                >
                  <div className="slogan">
                    <p
                      style={{
                        fontSize: "2vw",
                        marginTop: "2vh",
                        fontFamily: "Poppins",
                      }}
                    >
                      {deskripsi}
                    </p>
                    <br />
                    <p
                      style={{
                        fontSize: "2vw",
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                      }}
                    >
                      {title2}
                    </p>

                    <div
                      className="login"
                      style={{
                        padding: "0 3vw",
                        cursor: "pointer",
                        background: "#2F318B",
                        borderRadius: "25px",
                        width: "15vw",
                        height: "5vh",
                        marginTop: "2vh",
                      }}
                      onClick={() => setShowLogin(true)} // Menampilkan login popup
                    >
                      <p
                        style={{
                          fontSize: "1vw",
                          textAlign: "center",
                          fontFamily: "Poppins",
                          color: "#fff",
                          fontWeight: "bold",
                          margin: 0,
                          lineHeight: "5vh",
                        }}
                      >
                        Login
                      </p>
                    </div>
                    <p style={{ fontSize: "13px", fontFamily: "Poppins" }}>
                      {deskripsi2}
                    </p>
                    <div className="overlay">
                      <img
                        src={overlay}
                        alt={appName}
                        style={{
                          width: "25vw",
                          height: "60vh",
                          marginTop: "1vh",
                          marginLeft: "-30vw",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <style jsx>
              {`
                @media (max-width: 768px) {
                  .d-md-none {
                    display: none;
                  }
                  .d-block {
                    display: block;
                  }
                }

                @media (min-width: 769px) {
                  .d-none {
                    display: none;
                  }
                  .d-md-flex {
                    display: flex;
                  }
                }
              `}
            </style>
          </div>
        </div>
      </div>

      {/* Login Popup */}
      {showLogin && (
        <div className="login-popup" onClick={handleClosePopup}>
          <div className="login-popup-content">
            <Login onClose={() => setShowLogin(false)} /> {/* Popup Login dengan tombol close */}
          </div>
        </div>
      )}

      <style jsx>{`
        .login-popup {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
        }
        .login-popup-content {
          background: white;
          padding: 2rem;
          border-radius: 10px;
          width: 90%;
          max-width: 500px;
        }
      `}</style>
    </>
  );
}

export default Index;
